const imgsRS = [
  { src: "./imgs/RS7.png" },
  { src: "./imgs/RS6.png" },
  { src: "./imgs/RS5.png" },
  { src: "./imgs/RS4.png" },
  { src: "./imgs/RS3.png" },
  { src: "../imgs/RS2.png" },
  { src: "../imgs/RS1.png" },
];

export default imgsRS;
